var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import i18n from "../i18n/i18n";
import NotificationsList from '../components/notifications/NotificationsList';
export var NotificationsWidget = function () {
    var currentLanguage = document.getElementById("language-code").value;
    React.useEffect(function () {
        var _a;
        // @ts-ignore
        var langCode = ((_a = document.getElementById("language-code")) === null || _a === void 0 ? void 0 : _a.value) || "fr";
        if ((langCode === null || langCode === void 0 ? void 0 : langCode.length) === 2) {
            i18next.changeLanguage(langCode);
        }
    }, []);
    return (_jsx(Suspense, __assign({ fallback: "...loading" }, { children: _jsx(I18nextProvider, __assign({ i18n: i18n }, { children: _jsx(NotificationsList, { currentLanguage: currentLanguage, channel: "forum", widgetDomID: "notification-widget", widgetOpenedEvenListener: "forumNotificationsDropDownOpened", widgetClosedEvenListener: "forumNotificationsDropDownClosed" }) })) })));
};
var domNode = document.querySelector("#notification-widget");
if (domNode) {
    var root = createRoot(domNode);
    root.render(_jsx(NotificationsWidget, {}));
}
